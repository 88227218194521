@import '../../../../variables';

.container {
  display: flex;
  gap: 8px;
}

.title{
  font-size: 12px;
  white-space: nowrap;
  & span:last-child{
    color: $raven-grey;
  }
}
