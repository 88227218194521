div.container {
  box-shadow: none;
  width: 100%;
  &::before {
    display: none;
  }
}

div.summary {
  color: #b0b2c0;
  font-size: 12px;
  text-align: center;
  max-height: 40px;
  min-height: 40px !important;
  justify-content: center;
  word-break: break-all;

  & > div:nth-child(1) {
    flex-grow: 0;
    display: flex;
    align-items: center;
    gap: 4px;

    & > svg {
      padding-right: 0;
    }
  }

  & > div:nth-child(2) {
    position: static !important;
    transform: translateY(0) !important;
  }

  &[aria-expanded="true"] {
    &>div:nth-child(2) {
      transform: rotate(180deg) !important;
    }
  }
}

.details {
  justify-content: center;
  & > div {
    max-width: 800px;
  }
}