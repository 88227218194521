@import '../../../../variables';

.container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding-top: 18px;
  height: 265px;
}

.inputContainer {
  display: flex;
  gap: 8px;
}

.inputWrap {
  width: 150px;
  position: relative;
  z-index: 10000;
}

.input {
  outline: none;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-size: 14px;
  padding: 14px 24px;
  line-height: 19px;
  max-height: 45px;
  border: 1px solid #d7d8df;
  &:hover {
    border: 1px solid rgba(53, 64, 82, 0.5);
  }
  &::placeholder {
    color: #999;
  }
}

.item {
  color: var(--color-oxford-blue);
  padding: 8px 12px;
}

.menu {
  min-width: 150px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
  background: rgba(255, 255, 255, 0.9);
  padding: 2px 0px;
  font-size: 90%;
  position: absolute;
  overflow: auto;
  z-index: 10000;
}