@import '../../../../variables';

.container {
  border-radius: 10px;
  border: 1px solid rgba(53, 64, 82, 0.1);
  position: relative;
  margin: 10px 0;
  width: 100%;

  &:hover {
    i {
      display: block;
    }
  }
}

.header {
  padding: 18px;
  font-size: 14px;
  border-bottom: 1px solid var(--color-issabeline-grey);
  display: flex;
}

.body {
  padding: 24px 0 24px 24px;
}

.footer {
  border-top: 1px solid var(--color-issabeline-grey);
  padding: 24px;
  display: flex;
  justify-content: flex-end;
}

.title {
  color: var(--color-text-primary);
  margin: 0;
  font-weight: 700;
}

.infoIcon {
  cursor: pointer;
  margin-left: 6px;
}

.labels {
  display: flex;
  gap: 10px;
  padding-right: 32px;

  p {
    width: 50%;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-oxford-blue);
  }
}

.iconWrap {
  cursor: pointer;
  display: none;
  align-items: center;
  margin-left: 4px;
  position: absolute;
  right: 4px;
  top: 6px;
}

.attributeWrap {
  display: flex;
  flex-flow: row;
  margin-bottom: 9px;
  gap: 10px;
  position: relative;
  padding-right: 32px;

  &:hover > u {
    display: flex;
  }
}

.warning {
  position: absolute;
  color: #ff6666;
  font-size: 12px;
}

.input {
  border: 1px solid rgba(53, 64, 82, 0.1);
  outline: none;
  height: 38px;
  border-radius: 4px;
  width: 214px;
  padding: 8px 12px;
  color: rgba(53, 64, 82, 0.7);
}

.deleteTemplate {
  right: 28px;
  top: -16px;
  position: absolute;
  display: none;
  transition: all 0.5s ease;
}

.deleteIcon {
  background: var(--color-white);
  border: 1px solid var(--color-issabeline-grey);
  box-shadow: 0 3px 6px var(--color-issabeline-grey);
  width: 40px;
  height: 40px;
  position: absolute;
  right: -40px;
  display: block;
}
