@import '../../../../variables';

.container {
  position: relative;
  width: 100%;
}
.statusCode {
  font-size: 16px;
  color: var(--color-camarone-green);
  text-align: end;
  margin-bottom: 12px;
}
.tabs {
  display: flex;
  flex-direction: column;
}
.activeTab {
  font-size: 14px;
  color: var(--color-button-primary);
  cursor: pointer;
  margin: 4px;
}
.tab {
  font-size: 14px;
  color: $oxford-blue;
  cursor: pointer;
  margin: 4px;
}
.body {
  display: flex;
  gap: 24px;
}
.input {
  border-radius: 10px;
  border: 1px solid var(--color-issabeline-grey);
  flex: 1;
  height: 250px;
  padding: 12px;
  padding-bottom: 0;
  overflow: auto;
}
.body{
  font-size: 12px;
}
.jsonPretty {
  & > pre {
    margin-bottom: 0;
    min-height: 236px;
  }
}